import { createContext, useContext } from 'react'
import { apiCall } from '../api'
import { useAuth } from './auth'
import { useWeb3, NotificationTopic } from './web3'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import toast from 'react-hot-toast';

const Bonding = createContext(null)

const BondingProvider = ({ children }) => {
    const { tokens } = useAuth()
    const { sendBatch } = useWeb3()

    const bondsQuery = useQuery({
        queryKey: ['getBonds'],
        queryFn: async () =>
            await apiCall(
                '/bonding',
                {
                    method: 'GET',
                },
                tokens
            ),
    })

    const buyBonds = async (data) => {
        try {
            const transactions = await apiCall(
                '/bonding',
                {
                    payload: {
                        ..._.pick(data, ['amount', 'lock_id', 'token', 'input_amount']),
                        amount: String(data.amount),
                    },
                    method: 'POST',
                },
                tokens
            )
            await sendBatch(transactions, NotificationTopic.DEPOSIT)
        } catch (error) {
            toast.error('Could not add stake')
            console.error(`could not add stake: ${error}`)
        }
    }

    const getPrice = async (rfrm, amount, lock, token) => {
        // check if amount is a number
        if (_.isNaN(parseFloat(amount)) || parseFloat(amount) <= 0) {
            return
        }

        try {
            const { price } = await apiCall(
                '/bonding/price',
                {
                    payload: {
                        input_amount: rfrm,
                        token,
                        amount: amount,
                        lock_id: parseInt(lock),
                    },
                    method: 'POST',
                },
                tokens
            )
            return price
        } catch (error) {
            console.error(`could not get price: ${error}`)
        }
    }

    return (
        <Bonding.Provider
            value={{ buyBonds, getPrice, bondsQuery}}
        >
            {children}
        </Bonding.Provider>
    )
}

const useBonding = () => {
    const context = useContext(Bonding)

    if (context === undefined) {
        throw new Error('useBonding must be used within a BondingProvider')
    }

    return { ...context }
}

export { useBonding, BondingProvider }
