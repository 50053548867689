import { classNames } from '../utils/helper'

const Button = ({ children, ...props }) => {
    const { className, disabled } = props
    return (
        <button
            {...props}
            className={classNames(
                disabled ? 'opacity-50 pointer-events-none' : 'opacity-100',
                `bg-grey-100 hover:bg-white px-6 text-grey-400 font-space py-2.5 rounded-[4px] text-xs uppercase tracking-wider duration-150 ${className} `
            )}
        >
            {children}
        </button>
    )
}

export default Button
