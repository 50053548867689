import { useField } from 'formik'
import {classNames} from '../../utils/helper';

const SelectItem = ({ label, defaultValue, options, ...props }) => {
    const [field, meta] = useField(props)
    const { value } = props

    return (
        <div className="w-full flex items-center justify-between border-t-[1px] border-grey-300 pt-2 mb-4">
            <label htmlFor={field.name}
                   className="text-label uppercase ">{label}</label>
            <select {...field} {...props}
                    className={classNames(value === '' ? "text-grey-300/50" : "text-grey-100", "leading-[12px] focus:outline-primary icon-select px-4 form-item h-[37px] w-7/12 bg-grey-400 text-input")}>
                !!defaultValue && <option value="" disabled hidden className="text-grey-400 rounded-none">{defaultValue}</option>
                {options.map(({value, label}) => <option className="text-grey-100 rounded-none focus:rounded-none"
                                                         key={value} value={value}>{label}</option>)}
            </select>
            {meta.touched && meta.error ? <span className="text-primary text-2xs">{meta.error}</span> : null}
        </div>
    )
}

export default SelectItem
