import { lazy } from 'react'

const Dashboard = lazy(() => import('../pages/Dashboard'))
const Trading = lazy(() => import('../pages/Trading'))
const Bonds = lazy(() => import('../pages/Bonds'))
const Staking = lazy(() => import('../pages/Staking'))
const Voting = lazy(() => import('../pages/Voting'))
const Leaderboard = lazy(() => import('../pages/Leaderboard'))

const routes = [
    {
        path: '/',
        exact: true,
        menuItem: true,
        title: 'Overview',
        element: <Dashboard />,
    },
    // {
    //     path: '/soon1',
    //     exact: true,
    //     menuItem: true,
    //     title: 'Soon1',
    //     element: <DashboardSoon/>
    // },
    // {
    //     path: '/soon2',
    //     exact: true,
    //     menuItem: true,
    //     title: 'Soon2',
    //     element: <Soon/>
    // },
    {
        path: '/trading',
        exact: true,
        menuItem: true,
        title: 'Trading',
        element: <Trading />,
    },
    /*    {
        path: '/DAOs',
        exact: true,
        menuItem: true,
        title: 'DOAs',
        element: <Daos/>
    },*/
    {
        path: '/bonds',
        exact: true,
        menuItem: true,
        title: 'Bonds',
        element: <Bonds />,
    },
    {
        path: '/staking',
        exact: true,
        menuItem: true,
        title: 'Staking',
        element: <Staking />,
    },
    {
        path: '/voting',
        exact: true,
        menuItem: true,
        title: 'Voting',
        element: <Voting />,
    },

    {
        path: '/leaderboard',
        exact: true,
        menuItem: true,
        title: 'Leaderboard',
        element: <Leaderboard />,
    },
]

export default routes
